import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Image from 'next/legacy/image';
import useWindowSize from '@components/_hooks/useWindowSize';
import Link from 'next/link';
import dynamic from 'next/dynamic';

const DefaultButton = dynamic(() => import('../Reusables/Theme/DefaultButton'));
const HomeBanner = dynamic(() => import('./HomeBanner'));

const HomeCategories = (props) => {
    const {
        categories,
        home_grid_columns
    } = props;


    const {isMobile} = useWindowSize();
  
  
    const renderGridItems = (all = false, start_index, end_index = 2) => {
        const _cat = {};
        Object.keys(categories).forEach(k => {
            if(all){
                _cat[k] = categories[k];
            }
            else if ((parseInt(k) <= end_index) && (parseInt(k) >= start_index)) {
                _cat[k] = categories[k];
            } 
            // else {
            //     _hidden[k] = categories[k];
            // }
        });


        return (
            <>
                {Object.entries(_cat).map(([key, category], idx) => (
                    category.map((_cat, index) => (
                    <Grid item xs={4} sm={4} md={4} lg={parseInt(home_grid_columns)}  key={index}>
                        <Link href={`/${_cat.slug}`}>
                            <Box 
                                className="position-relative pointer" 
                                sx={{
                                    width: '100%', 
                                    height: {
                                        xs: '150px',
                                        lg: '300px'
                                    },
                                    overflow: 'hidden',
                                    transition: 'all 0.4s ease',
                                    ':hover': {
                                        backgroundColor: 'black',
                                        'button': {
                                            backgroundColor: 'primary.dark'
                                        }
                                    },
                                    '.category-image': {
                                        transition: 'all 0.4s ease',
                                    },
                                    ':hover .category-image': {
                                        transition: 'all 0.4s ease',
                                        transform: 'scale(1.05)',
                                        opacity: '0.6'
                                    }
                                }}
                            >
                                {!isMobile && (
                                    <Box
                                        className="d-flex justify-center w-100"
                                        sx={{
                                            zIndex: 5,
                                            position: 'absolute',
                                            bottom: {
                                                sm: 5,
                                                md: 20
                                            }
                                        }}
                                    >
                                        <DefaultButton
                                            sx={{
                                                minWidth: {
                                                    sm: '150px',
                                                    md: '175px'
                                                },
                                                borderRadius: '3px',
                                                height: '35px'
                                            }}
                                            color="primary"
                                            variant="contained"
                                        >
                                            {_cat.name}
                                        </DefaultButton>
                                    </Box>

                                )}
                                <Image 
                                    className="category-image"
                                    src={_cat.image} // Update with your image path
                                    alt={`Image ${index + 1}`}
                                    layout="fill" // Using fill layout
                                    objectFit="cover"
                                    priority={true}
                                />
                            </Box>
                        </Link>

                        {isMobile && (
                            <div className="d-flex justify-center">
                                    <Typography color="primary" sx={{fontWeight: 500}} className="text-center">
                                        {/* {item.name == 'Browse by Event' ? 'By Event' : item.name} */}
                                        {_cat.name}
                                    </Typography>
                            </div>
                        )}
                        </Grid>
                    ))
                ))}
            </>
        )
    };
  

    return (
        <Grid className="position-relative" container 
            rowSpacing={{
                xl: 3,
                lg: 3,
                md: 2,
                sm: 1,
                xs: 1
            }}
            columnSpacing={{
                xl: 3,
                lg: 3,
                md: 2,
                sm: 1,
                xs: 1,
            }}
        >
            {!isMobile && renderGridItems(false, 1, 2)}
            {
                !isMobile && (
                    <Grid item xs={12}>
                        <HomeBanner/>
                    </Grid>
                )
            }
            {!isMobile && renderGridItems(false, 3, 3)}
            {isMobile && renderGridItems(true, 1, 2)}

      </Grid>
    )
}

export default HomeCategories